<template>
  <div class="wrapper">
    <section class="header">
      <a href="/" class="logo"></a>

      <div class="course-item__progress d-flex align-items-center mx-2">
        <div class="course-item__progress-bg flex-grow-1">
          <div class="course-item__progress-percent" style="width: 48%"></div>
        </div>
        <div class="course-item__percent">48%</div>
      </div>

      <b-dropdown id="user-menu" text="Александр Петров">
        <b-dropdown-item v-b-modal.profile href="#">Редактировать профиль</b-dropdown-item>
        <b-dropdown-item href="#">Сертификаты</b-dropdown-item>
        <b-dropdown-item class="logout" href="#">Выйти</b-dropdown-item>
      </b-dropdown>

      <b-modal id="profile" class="modal-profile" hide-footer size="lg" title="Редактирование профиля">
        <div class="modal-profile">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-2" label="Имя" label-for="input-2">
              <b-form-input
                  id="input-2"
                  v-model="form.name"
                  placeholder="Александр"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Фамилия" label-for="input-3">
              <b-form-input
                  id="input-3"
                  v-model="form.lastname"
                  placeholder="Петров"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Эл. почта" label-for="input-4">
              <b-form-input
                  id="input-4"
                  v-model="form.phone"
                  placeholder="+7 (999) 999 99-99"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="Email" label-for="input-1">
              <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="apetrov74@gmail.com"
                  required
              ></b-form-input>
            </b-form-group>


            <div class="row mt-5">
              <div class="col-auto">
                <b-button type="submit" variant="success">Сохранить</b-button>
              </div>
              <div class="col-auto">
                <b-button type="submit" variant="outline-dark">Отменить</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>

    </section>
    <section class="block-study block-study__complete">
      <h1 class="mb-0">Занимательная математика</h1>
      <div class="block-study__description">Курс завершен</div>

      <div class="block-study__complete-result">
        <div class="row">
          <div class="col">
            <h2>Поздравляем! </h2>
            <div class="block-study__complete-text">Вы успешно прошли курс.</div>
          </div>
          <div class="col-auto">
            <div class="block-study__complete-diagramm"><img src="/images/diagramm-result.svg"></div>
          </div>
        </div>
      </div>


        <div class="block-study__complete-data">
          <div class="block-study__complete-docs">
            <div class="text-label mb-2">Скачать свидетельство:</div>
            <div class="d-flex justify-content-between">
              <a class="btn btn-outline-dark" href="#">Скачать в png</a>
              <a class="btn btn-outline-dark" href="#">Скачать в pdf</a>
            </div>
          </div>

          <div class="block-study__complete-share">
            <div class="text-label mb-2">Это было круто!</div>
            <p>Расскажите об этом вашим друзьям!</p>
            <div class="d-flex block-study__complete-socials justify-content-between">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8276 38V24.0779H31.5262L32.2276 18.6283H26.8276V15.1586C26.8276 13.5821 27.2683 12.5145 29.5276 12.5145H32.4138V7.64828C31.0219 7.50442 29.6234 7.43398 28.2241 7.43724C24.0717 7.43724 21.2414 9.96966 21.2414 14.6186V18.6283H16.5552V24.0779H21.2414V38H9.69166C7.0171 38 6.04724 37.7215 5.06946 37.1986C4.09169 36.6757 3.32432 35.9083 2.8014 34.9305C2.27848 33.9528 2 32.9829 2 30.3083V9.69166C2 7.0171 2.27848 6.04724 2.8014 5.06946C3.32432 4.09169 4.09169 3.32432 5.06946 2.8014C6.04724 2.27848 7.0171 2 9.69166 2H30.3083C32.9829 2 33.9528 2.27848 34.9305 2.8014C35.9083 3.32432 36.6757 4.09169 37.1986 5.06946C37.7215 6.04724 38 7.0171 38 9.69166V30.3083C38 32.9829 37.7215 33.9528 37.1986 34.9305C36.6757 35.9083 35.9083 36.6757 34.9305 37.1986C33.9528 37.7215 32.9829 38 30.3083 38H26.8276Z" fill="#4267B2"/>
              </svg>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.0884 9.61136C39.3631 8.68316 39.0884 8 37.7666 8H33.3929C32.2791 8 31.7667 8.58662 31.492 9.24008C31.492 9.24008 29.2643 14.6682 26.1158 18.1879C25.0985 19.2052 24.6307 19.532 24.0738 19.532C23.7991 19.532 23.3758 19.2052 23.3758 18.277V9.61136C23.3758 8.49752 23.0639 8 22.1431 8H15.267C14.569 8 14.1532 8.51979 14.1532 9.00246C14.1532 10.0569 15.7274 10.3019 15.8908 13.2722V19.7176C15.8908 21.1285 15.6383 21.3884 15.0814 21.3884C13.5963 21.3884 9.98744 15.938 7.84886 9.70046C7.41818 8.49009 6.99492 8 5.87365 8H1.49997C0.25247 8 0 8.58662 0 9.24008C0 10.3985 1.48512 16.1533 6.91324 23.7571C10.5295 28.9476 15.6235 31.7619 20.257 31.7619C23.0416 31.7619 23.3832 31.1382 23.3832 30.0615C23.3832 25.1012 23.1308 24.6334 24.5268 24.6334C25.1728 24.6334 26.2866 24.9601 28.8856 27.4625C31.8558 30.4327 32.3459 31.7619 34.0093 31.7619H38.3829C39.6304 31.7619 40.2616 31.1382 39.8978 29.9055C39.0661 27.314 33.4449 21.9824 33.1924 21.626C32.5464 20.7943 32.7321 20.423 33.1924 19.6805C33.1999 19.673 38.5389 12.1583 39.0884 9.61136Z" fill="#4A76A8"/>
              </svg>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6 2H35.4C36.8359 2 38 3.16406 38 4.6V35.4C38 36.8359 36.8359 38 35.4 38H4.6C3.16406 38 2 36.8359 2 35.4V4.6C2 3.16406 3.16406 2 4.6 2ZM7.42449 15.5959V32.7347H12.7837V15.5959H7.42449ZM10.0776 7.05306C8.37959 7.05306 7 8.48571 7 10.1837C7 11.8816 8.37959 13.2612 10.0776 13.2082C11.7755 13.2082 13.1551 11.8286 13.1551 10.1306C13.1551 8.43265 11.7755 7.05306 10.0776 7.05306ZM16.1265 15.5959H16.1796V32.7347H21.4857V24.2449C21.4857 22.0163 21.9102 19.8408 24.6694 19.8408C27.3755 19.8408 27.4286 22.3878 27.4286 24.4041V32.7347H32.7347V23.3429C32.7347 18.7265 31.7265 15.1714 26.3143 15.1714C23.7143 15.1714 21.9633 16.6041 21.2735 17.9306H21.2204V15.5959H16.1265Z" fill="#2767B2"/>
              </svg>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3218 36C9.15088 36 5.26856 34.7463 2 32.5975C2.57781 32.6674 3.16576 32.7032 3.76188 32.7032C7.22217 32.7032 10.4068 31.4924 12.9346 29.4612C9.70262 29.4 6.975 27.2104 6.0352 24.2015C6.48607 24.2899 6.9488 24.3373 7.42469 24.3373C8.09836 24.3373 8.75084 24.2448 9.37066 24.0717C5.99175 23.3761 3.44598 20.315 3.44598 16.6455C3.44598 16.6137 3.44598 16.5819 3.4465 16.5503C4.4424 17.1176 5.58117 17.4582 6.79197 17.4975C4.81018 16.1394 3.50616 13.8213 3.50616 11.1938C3.50616 9.80587 3.87038 8.50488 4.50613 7.38632C8.14893 11.9683 13.5911 14.9835 19.7295 15.2993C19.6036 14.745 19.5382 14.167 19.5382 13.5734C19.5382 9.39093 22.8452 6 26.9243 6C29.0485 6 30.968 6.9198 32.3154 8.39173C33.9977 8.052 35.5784 7.4217 37.0054 6.55388C36.4539 8.32233 35.2829 9.80654 33.7579 10.7439C35.252 10.5608 36.6754 10.1537 38 9.55134C37.0098 11.0701 35.7575 12.4041 34.3145 13.4718C34.3288 13.7966 34.3359 14.1232 34.3359 14.4516C34.3359 24.4597 26.907 36 13.3218 36Z" fill="#1BA1F1"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="block-study__complete-footer">
          Хотите улучшить результат? <a href="#">Пройти тест заново</a>
        </div>

    </section>
  </div>
</template>

<script>

export default {
  name: "Study",
  data() {
    return {
      form: {
        email: '',
        phone: '',
        name: '',
        lastname: '',
      },
      show: true
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.lastname = ''
      this.form.phone = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style scoped>
</style>